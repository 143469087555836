import type { ReactNode } from "react";
import { RxArrowLeft } from "react-icons/rx";
import { useNavigate } from "react-router";
import AudioPlayer from "../../AudioPlayer";
import { Title } from "./Typography";

interface ArticleWrapperProps {
  title: string;
  content: string;
  createdAt: number;
  audioUrl?: string;
  centerTitle?: boolean;
  removeTitle?: boolean;
  children: ReactNode;
}

export const ArticleWrapper = ({
  title,
  audioUrl,
  centerTitle,
  removeTitle,
  children,
}: ArticleWrapperProps) => {
  const navigate = useNavigate();
  const redirectToArticles = () => navigate("/articles");

  return (
    <div>
      <button
        type="button"
        onClick={redirectToArticles}
        className="p-2 mb-5 hover:bg-opacity-90 hover:cursor-pointer bg-[#b2aca0] w-fit"
      >
        <RxArrowLeft className="w-5 h-5 text-white" />
      </button>

      {!removeTitle && <Title center={centerTitle}>{title}</Title>}
      {audioUrl && <AudioPlayer src={audioUrl} />}

      <section className={`${audioUrl && "pt-6"}`}>{children}</section>
    </div>
  );
};
